<template>
    <div class="credits_main">

        <div class="page-header">
            <el-row>
                <el-col :span="24">
                    <div class="header-desc">
                        <p>Organization name: ABC school</p>
                        <p>Organization ID: ABCschool</p>
                    </div>
                </el-col>
            </el-row>
        </div>

        <el-row :gutter="20">

            <el-col :span="12">
                <el-card shadow="always">
                    <div class="credits_card">
                        <div class="credits_remain mb-20">
                            <p>Remaining Credits:1000</p>
                            <p>1 issue = 3 Credits</p>
                        </div>
                        <el-row :gutter="20">
                            <el-col :span="12" align="left"><b>Accounts: </b></el-col>
                            <el-col :span="12" align="right"><b>Assign Issues</b></el-col>
                        </el-row>
                        <el-row :gutter="20" class="mt-20">
                            <el-col :span="12" align="left">ABCschool(Head branch) </el-col>
                            <el-col :span="12" align="right">
                                <el-input-number size="mini" v-model="assign_issues.branch"></el-input-number>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20" class="mt-20">
                            <el-col :span="12" align="left">ABCschool - kowloon</el-col>
                            <el-col :span="12" align="right">
                                <el-input-number size="mini" v-model="assign_issues.kowloon"></el-input-number>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20" class="mt-20">
                            <el-col :span="12" align="left">ABCschool - HK</el-col>
                            <el-col :span="12" align="right">
                                <el-input-number size="mini" v-model="assign_issues.hk"></el-input-number>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20" class="mt-20">
                            <el-col :span="12" align="left">ABCschool - NT</el-col>
                            <el-col :span="12" align="right">
                                <el-input-number size="mini" v-model="assign_issues.nt"></el-input-number>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20" class="mt-20">
                            <el-col :span="24" align="center">
                                <el-button type="primary">Confirm</el-button>
                            </el-col>
                        </el-row>
                    </div>
                </el-card>
            </el-col>

            <el-col :span="12">
                <el-card shadow="always">
                    <div class="credits_chart credits_card">
                        <h4>Design in pie chart</h4>
                        <div class="credits_chart_text">
                            <p>Remaining Credits: 1000</p>
                            <p>Free: XX    Purchased: XX</p>
                        </div>
                        <el-row :gutter="20" class="mt-20">
                            <el-col :span="24" align="center">
                                <el-button type="primary">Topup</el-button>
                            </el-col>
                        </el-row>
                    </div>
                </el-card>
            </el-col>

            <el-col :span="24" class="mt-30">
                <el-tabs type="border-card">
                    <el-tab-pane label="ABCschool - HK">
                        <div id="myChart"
                            :style="{ width: '800px', height: '387px' }" style="margin: 30px auto;"></div>
                    </el-tab-pane>
                    <el-tab-pane label="ABCschool - KL">ABCschool - KL</el-tab-pane>
                    <el-tab-pane label="ABCschool - NT">ABCschool - NT</el-tab-pane>
                </el-tabs>
            </el-col>
        </el-row>
    </div>
</template>

<script>
  export default {
    data() {
      return {
        assign_issues:{
            branch: 55,
            kowloon: 15,
            hk: 30,
            nt: 26
        }
      }
    },
    mounted() {
        //this.$root => app
        let myChart = this.$root.echarts.init(
        document.getElementById("myChart")
        );
        // 绘制图表
        myChart.setOption({
            title: { text: "ECert issue Report" },
            tooltip: {},
            xAxis: {
                data: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            },
            yAxis: {},
            series: [
                {
                name: "Amount",
                type: "bar",
                data: [5, 20, 36, 10, 10, 20, 15, 6, 21, 35, 12, 9],
                },
            ],
        });
    },
  };
</script>

<style>
.el-input-number__decrease, .el-input-number__increase{
    top: 2px;
}
</style>

<style scoped>
.credits_remain p{
    height: 30px;
    line-height: 30px;
    color: #666666;
    font-size: 14px;
}
.credits_card{
    height: 356px;
}
.credits_chart{
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.credits_chart h4{
    font-size: 24px;
}
.credits_chart .credits_chart_text{
    padding: 50px 0px;
}
.credits_chart .credits_chart_text p{
    height: 35px;
    line-height: 35px;
    font-size: 19px;
    color: #666;
}
</style>